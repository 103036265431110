@import '/apps/wt/webtools/sparta_build_server/tmp/9C7439D6/artifacts/components/utilities/global/sparta-style-utility/4.9.5/scss/_sparta-project.scss';
$moduleNameSpace: dynamic-name-space;

html.mda {
  [data-sparta-container] .#{$moduleNameSpace} {
    display: none;
  }
}

[data-sparta-container] .spa-page-title {
  margin: 0 auto;
  padding: 10px 0;
  position: relative;

  &.ada-hidden {
    padding: 0;
  }

  &-inset {
    height: auto !important;
    margin: 0 auto;
  }

  &--red {
    background-color: $color-regal-one;
    color: $color-brand-tertiary;
  }

  &--gray {
    background-color: $color-gray-s05;
    color: $color-black;
  }

  &--white {
    background-color: $color-brand-tertiary;
    color: $color-black;
  }
  &--newgray {
    background-color: #ECE6DD ;
    color: #000000;
  }

  &--red-text {
    .heading {
      color: $bank-regal-red;
    }
  }

  &--white-text {
    .heading {
      color: #fff;
    }
  }

  &--black-text {
    .heading {
      color: #333;
    }
  }
  &--newgray-text {
    .heading {
      color: #000000;
    }
  }

  .columns,
  .row {
    height: auto;
  }

  .heading {
    margin: 0;
    font-size: 16px;
    line-height: 24px;

    @media #{$small-up} {
      font-size: 16px;
      line-height: 24px;
    }

    @media #{$medium-up} {
      font-size: 20px;
      line-height: 28px;
    }

    @media #{$large-up} {
      font-size: 28px;
      line-height: 36px;
    }
  }
}

.title-module-fixed {
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 1296px;
  z-index: 1002;
  visibility: hidden;
}

.secondary-sticky-module-fixed {
  transform: translate3d(0, 0, 0);
  visibility: hidden;
  position: fixed;
  top: 0;
  margin: 0;
  width: 100%;
  max-width: 1296px;
  z-index: 1002;
}

.vis-show {
  visibility: visible;
}

.vis-hide {
  visibility: hidden !important; 
}

[data-sparta-container] .spa-page-title.spa-page-title--has-flagscape.spa-page-title--red-gradient {
  &::after {
    background-position: 50% center;
  }

    &::before {
    background-position: 59% 28%;
  }
}